import React from "react"
import Container from "../components/Container/Container";
import SmallHero from "../components/SmallHero/SmallHero"
const links = [
  {
  label: 'Een huis kopen',
  link: '/diensten/kopen'
  },
  {
    label: 'Een huis verkopen',
    link: '/diensten/verkopen'
  },
  {
    label: 'Mijn huis laten taxeren',
    link: '/diensten/taxatie'
  }
]


const CommunityPage = () => (
  <>
    <SmallHero></SmallHero>
    <Container styles={'py-12'}>
      <div className="w-4/12 mx-auto">
        <input type='text' placeholder="Vul hier jouw unieke code in" className="border-2 border-light p-3 w-full"></input>
        <button type="submit" className="bg-blue p-3 w-full mt-3 text-white">Login</button>
      </div>
      
    </Container>
  </>
)

export default CommunityPage;